<template>
  <div>
    <dashboard-page-title  :showAddBtn="false" >الطلبات</dashboard-page-title>
    <main-table :baseURL="VUE_APP_MARKETPLACE_LINK"  :fields="fields" list_url="admin/orders"></main-table>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import { VUE_APP_MARKETPLACE_LINK } from '@/config/constant'
export default {
  components: {},
  mounted () {
    core.index()
  },
  data () {
    return {
      VUE_APP_MARKETPLACE_LINK,
      fields: [
        { label: 'Id', key: 'id', class: 'text-right', sortable: true },
        { label: 'اسم العميل', key: 'order_address.name', class: 'text-right' },
        { label: 'اسم المتجر', key: 'store.name', class: 'text-right' },
        { label: 'العنوان', key: 'order_address.address', class: 'text-right' },
        { label: 'رقم الهاتف', key: 'order_address.phone', class: 'text-right' },
        { label: 'المبلغ الإجمالى', key: 'total_amount', class: 'text-right' },
        { label: 'الحالة', key: 'status', type: 'status', class: 'text-right' },
        { label: 'تاريخ الإنشاء', key: 'created_at', class: 'text-right' }
      ]
    }
  },
  methods: {}
}
</script>
